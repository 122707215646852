import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { IAccountItem, IAccountLocation, IProviderPaymentPage } from 'src/types/account';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

interface IAccountResponse {
  account?: IAccountItem;
  accounts?: IAccountItem[];
  locations?: IAccountLocation[];
  stats?: {
    [key: string]: number;
  };
  error?: string;
  message?: string;
}

interface IProviderPaymentPagesResponse {
  paymentPages?: {
    [providerId: string]: IProviderPaymentPage[];
  };
  paymentPage?: IProviderPaymentPage;
  error?: string;
}

interface VpaQrImageDataResponse {
  providerId: string;
  qrImage: string;
  gateway?: string;
}

interface IAccountFlagsResponse {
  accountIds: string[];
  status?: string;
  bookmarked?: boolean;
  error?: string;
  message?: string;
}

interface AccountsState {
  isLoading: boolean;
  error: string | null;
  serverSearched?: boolean;
  accounts: IAccountItem[];
  paymentPages: {
    [providerId: string]: IProviderPaymentPage[];
  };
  paymentPage: IProviderPaymentPage;
  accountLocations?: IAccountLocation[];
  vpaQrImages: {
    [key: string]: string | null;
  },
  vpaGateway: {
    [key: string]: string | null;
  },
  stats: {
    [key: string]: number;
  };
  account: IAccountItem | null;
  category: string | null;
  other: string | null;
  sortBy: string | null;
}

const initialState: AccountsState = {
  isLoading: false,
  error: null,
  accounts: [],
  paymentPages: {},
  paymentPage: {},
  serverSearched: false,
  accountLocations: [],
  vpaQrImages: {},
  vpaGateway: {},
  stats: {},
  account: null,
  category: null,
  other: null,
  sortBy: 'createdAt',
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ACCOUNTS
    getAccountsSuccess(state, action: PayloadAction<IAccountResponse>) {
      state.isLoading = false;
      const newAccounts = action.payload.accounts ?? [];
      const existingAccounts = state.accounts;
      const allAccounts = existingAccounts.concat(newAccounts);
      const uniqueAccounts = allAccounts.filter((account, index, self) => {
        return index === self.findIndex((a) => a.accountId === account.accountId);
      });
      const sortBy = state.sortBy || 'createdAt';
      const sortedAccounts = uniqueAccounts.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.accounts = sortedAccounts;
      state.stats = action.payload.stats ?? {};
    },

    resetAccountsSuccess(state) {
      state.isLoading = false;
      state.accounts = [];
      state.stats = {};
    },

    resetPaymentPage(state) {
      state.isLoading = false;
      state.paymentPage = {};
    },

    // SEARCH ACCOUNTS
    searchAccountsSuccess(state, action: PayloadAction<IAccountResponse>) {
      state.isLoading = false;
      const accounts = action.payload.accounts ?? [];
      const sortBy = state.sortBy || 'createdAt';
      const sortedAccounts = accounts.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.accounts = sortedAccounts;
      state.serverSearched = true;
      state.stats = action.payload.stats ?? {};
    },

    // UPDATE ACCOUNT
    updateAccountSuccess(state, action: PayloadAction<IAccountResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const account_data = action.payload.account;
        if (account_data) {
          const accountIndex = state.accounts.findIndex(account => account.accountId === account_data.accountId);
          if (accountIndex !== -1) {
            state.accounts[accountIndex] = account_data; // Ensure we only update when account_data is not null.
          }
        }
      }
    },

    // GET ACCOUNT
    getAccountSuccess(state, action: PayloadAction<IAccountResponse>) {
      state.isLoading = false;
      state.account = action.payload.account || null;
    },

    // GET ACCOUNT LOCATIONS
    getAccountLocationsSuccess(state, action: PayloadAction<IAccountResponse>) {
      state.isLoading = false;
      state.account = action.payload.account || null;
      state.accountLocations = action.payload.locations ?? [];
    },

    // GET PROVIDER VPA QR IMAGE DATA
    getProviderVpaQrImageDataSuccess(state, action: PayloadAction<VpaQrImageDataResponse>) {
      state.isLoading = false;
      state.vpaQrImages[action.payload.providerId] = action.payload.qrImage || null;
      state.vpaGateway[action.payload.providerId] = action.payload.gateway || 'paytm';
    },

    // UPDATE ACCOUNT STATUS
    updateAccountsStatusSuccess(state, action: PayloadAction<IAccountFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { accountIds, status } = action.payload;
        accountIds.forEach(accountId => {
          const accountIndex = state.accounts.findIndex(account => account.accountId === accountId);
          if (accountIndex !== -1) {
            state.accounts[accountIndex].status = status || null;
          }
        });
      }
    },

    // UPDATE BOOKMARK STATUS
    updateBookmarkSuccess(state, action: PayloadAction<IAccountFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { accountIds, bookmarked } = action.payload;
        accountIds.forEach(accountId => {
          const accountIndex = state.accounts.findIndex(account => account.accountId === accountId);
          if (accountIndex !== -1) {
            state.accounts[accountIndex].bookmarked = bookmarked;
          }
        });
      }
    },

    //  SORT & FILTER PRODUCTS
    sortByAccounts(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },

    // GET ACCOUNT LOCATIONS
    getPaymentPagesSuccess(state, action: PayloadAction<IProviderPaymentPagesResponse>) {
      state.isLoading = false;
      state.paymentPages = action.payload.paymentPages ?? {};
      state.paymentPage = action.payload.paymentPage ?? {};
    },

    // GET ACCOUNT LOCATIONS
    getPaymentPageSuccess(state, action: PayloadAction<IProviderPaymentPagesResponse>) {
      state.isLoading = false;
      state.paymentPage = action.payload.paymentPage ?? {};
      state.paymentPages = action.payload.paymentPages ?? {};
    },

    // NO COST EMI STATIC LINK SENT
    sendNoCostEmiStaticLinkSuccess(state, action: PayloadAction<IAccountResponse>) {
      state.isLoading = false;
      state.error = action.payload.error ? (action.payload.message || null) : null;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const { 
  hasError,
  startLoading,
  sortByAccounts,
  resetPaymentPage,
  getAccountSuccess,
  getAccountsSuccess,
  resetAccountsSuccess,
  updateAccountSuccess,
  updateBookmarkSuccess,
  searchAccountsSuccess,
  getPaymentPageSuccess,
  getPaymentPagesSuccess,
  getAccountLocationsSuccess,
  updateAccountsStatusSuccess,
  sendNoCostEmiStaticLinkSuccess,
  getProviderVpaQrImageDataSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getAccounts(page: number, rowsPerPage: number, status?: string, subStatus?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      if (sortBy) {
        dispatch(sortByAccounts(sortBy))
      }
      let url = '/management/accounts/get_active_accounts?app=' + (app || 'brightpay') + '&page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      url = subStatus ? url + '&subStatus=' + subStatus : url;
      const response = await axiosBrightHub.get(url);
      dispatch(getAccountsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function searchAccounts(searchString: string, page: number, rowsPerPage: number, status?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      if (sortBy) {
        dispatch(sortByAccounts(sortBy))
      }
      let url = '/management/accounts/get_active_accounts?searchString=' + searchString + '&app=' + (app || 'brightpay') + '&page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      const response = await axiosBrightHub.get(url);
      dispatch(searchAccountsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLocationsByAccount(account: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/management/accounts/get_locations_by_account?app=' + (app || 'brightpay') + '&account_id=' + account;
      const response = await axiosBrightHub.get(url);
      dispatch(getAccountLocationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderVpaQrImageData(providerId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/management/accounts/get_location_vpa_qr_image_data?providerId=' + providerId;
      const response = await axiosBrightHub.get(url);
      dispatch(getProviderVpaQrImageDataSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export const getAccount = (accountId: string): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosBrightHub.post('/management/accounts/get_account', { accountId });
    dispatch(getAccountSuccess(response.data.account));
  } catch (error) {
    console.error(error);
    dispatch(hasError(error));
  }
};

// ----------------------------------------------------------------------

export function updateAccount(accountId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/accounts/update', { accountId, data });
      dispatch(updateAccountSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateAccountsStatus(accountIds: string[], status: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/accounts/update_status', { accountIds, status });
      dispatch(updateAccountsStatusSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBookmark(accountIds: string[], bookmarked: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/accounts/add_bookmark', { accountIds });
      dispatch(updateBookmarkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaymentPages(accountId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/brightpay/paymentpages/manage/by_account';
      console.log('accountId', accountId)
      if (accountId && accountId !== undefined && accountId !== 'undefined') {
        url = url + '?account_id=' + accountId;
      }
      const response = await axiosBrightHub.get(url);
      dispatch(getPaymentPagesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDefaultPaymentPagesByType(accountId: string, type: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/brightpay/paymentpages/manage/by_account_and_type';
      if (accountId && type) {
        url = url + '?account_id=' + accountId + '&type=' + type;
      } else if( type ) {
        url = url + '?type=' + type;
      }
      const response = await axiosBrightHub.get(url);
      dispatch(getPaymentPagesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPaymentPage(paymentPageId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/brightpay/paymentpages/manage/by_id?paymentPageId=' + paymentPageId;
      const response = await axiosBrightHub.get(url);
      dispatch(getPaymentPageSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendStaticNoCostEmiLink(providerId: string, mobile?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/brightpay/paymentpages/manage/send_static_no_cost_emi_link?providerId=' + providerId;
      if (mobile) {
        url = url + '&mobile=' + mobile;
      }
      const response = await axiosBrightHub.get(url);
      dispatch(sendNoCostEmiStaticLinkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error));
    }
  };
}