import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axios from 'src/utils/axios';
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { IAccountItem, IAccountLocation, IProviderPaymentPage } from 'src/types/account';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

interface CustomFile extends File {
    id?: string;
    path?: string;
    preview?: string;
    lastModifiedDate?: Date;
}

interface IActivationResponse {
    error?: string;
    message?: string;
    account?: IAccountItem;
    user?: any;
    activationStatus?: string;
    activationData?: any;
}

interface IAadhaarValidationTriggerResponse {
    triggered: boolean;
    maskedAadhaar: string;
    refId: string;
    message?: string;
    error?: boolean;
}

interface IAadhaarValidationResponse {
    isValid: boolean;
    name: string;
    message?: string;
    error?: boolean;
}

interface IBankValidationResponse {
    isValid: boolean;
    name?: string;
    branchName?: string;
    message?: string;
    error?: boolean;
}

interface AccountsState {
    isLoading: boolean;
    error: string | null;
    account?: IAccountItem;
    user?: any;
    activationStatus: string;
    activationData: {
        [key: string]: any;
    } | null;
    maskedAadhaar: string | null;
    aadhaarName: string | null;
    aadhaarValidationRefId: string | null;
    kycDocumentUrl: string | null;
    aadhaarVerificationTriggered: boolean;
    aadhaarValidated: boolean;
    isAadhaarValid: boolean;
    bankAccountValidated: boolean;
    isBankAccountValid: boolean;
    bankAccountHolderName: string | null;
    bankBranchName: string | null;
}

const initialState: AccountsState = {
    isLoading: false,
    error: null,
    activationStatus: 'init',
    activationData: null,
    kycDocumentUrl: null,
    aadhaarVerificationTriggered: false,
    aadhaarValidated: false,
    aadhaarName: null,
    maskedAadhaar: null,
    aadhaarValidationRefId: null,
    isAadhaarValid: false,
    bankAccountValidated: false,
    isBankAccountValid: false,
    bankAccountHolderName: null,
    bankBranchName: null,
};

const slice = createSlice({
    name: 'activation',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // SET KYC DOCUMENT URL
        setKycDocumentUrl(state, action: PayloadAction<{ fileUrl: string }>) {
            state.kycDocumentUrl = action.payload.fileUrl;
            state.isLoading = false;
        },

        // RESET KYC DOCUMENT URL
        resetKycDocumentUrl(state) {
            state.kycDocumentUrl = null;
        },

        // SET AADHAAR VALIDATED STATUS
        setAadhaarValidated(state, action: PayloadAction<IAadhaarValidationResponse>) {
            state.aadhaarValidated = true;
            state.aadhaarName = action.payload.name;
            state.isAadhaarValid = action.payload.isValid
            state.isLoading = false;
            state.error = action.payload.error === true ? (action.payload.message || null) : null;
        },

        // SET AADHAAR VERIFICATION TRIGGER STATUS
        setAadhaarVerficationTriggerStatus(state, action: PayloadAction<IAadhaarValidationTriggerResponse>) {
            state.aadhaarVerificationTriggered = true;
            state.maskedAadhaar = action.payload.maskedAadhaar;
            state.aadhaarValidationRefId = action.payload.refId;
            state.isLoading = false;
            state.error = action.payload.error === true ? (action.payload.message || null) : null;
        },

        // RESET ERROR
        resetError(state) {
            state.error = null;
        },

        // RESET AADHAAR VALIDATION
        resetAadhaarValidation(state) {
            state.aadhaarValidated = false;
            state.isAadhaarValid = false;
            state.maskedAadhaar = null;
            state.aadhaarName = null;
            state.aadhaarVerificationTriggered = false;
            state.aadhaarValidationRefId = null;
        },

        // SET BANK ACCOUNT VALIDATED STATUS
        setBankAccountValidated(state, action: PayloadAction<IBankValidationResponse>) {
            state.bankAccountValidated = true;
            state.isBankAccountValid = action.payload.isValid
            state.bankAccountHolderName = action.payload.name || null;
            state.bankBranchName = action.payload.branchName || null;
            state.error = action.payload.error === true ? (action.payload.message || null) : null;
            state.isLoading = false;
        },

        // GET ACCOUNT LOCATIONS
        updateProgressSuccess(state, action: PayloadAction<IActivationResponse>) {
            state.isLoading = false;
            if (action.payload.activationStatus) {
                state.activationStatus = action.payload.activationStatus;
            }
            if (action.payload.activationData) {
                state.activationData = {
                    ...state.activationData,
                    ...action.payload.activationData,
                };
            }
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    resetError,
    startLoading,
    setKycDocumentUrl,
    resetKycDocumentUrl,
    setAadhaarValidated,
    updateProgressSuccess,
    resetAadhaarValidation,
    setBankAccountValidated,
    setAadhaarVerficationTriggerStatus
} = slice.actions;

// ----------------------------------------------------------------------

export function getAcivationStatus() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.get('/account/activation/get_status');
            dispatch(updateProgressSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function updateActivationProgress(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/account/activation/update_progress', data);
            dispatch(updateProgressSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function completeActivation(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/account/activation/complete', data);
            dispatch(updateProgressSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function uploadKycDocument(file: CustomFile) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/account/activation/create_signed_url', { fileId: file.id, fileName: file.path });
            if (response.data) {
                await axios.put(response.data.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Content-Encoding': 'base64',
                        'Skip-Auth': 'true'
                    }
                });
                dispatch(setKycDocumentUrl(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function triggerAadhaarVerification(aadhaarNumber: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/account/activation/trigger_aadhaar_verification', { aadhaarNumber });
            dispatch(setAadhaarVerficationTriggerStatus(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function verifyAadhaarOtp(refId: string, otp: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/account/activation/validate_aadhaar_otp', { refId, otp });
            dispatch(setAadhaarValidated(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function validateBankAccount(data: any) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/account/activation/validate_bank_account', data);
            dispatch(setBankAccountValidated(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}