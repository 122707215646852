import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { ILeadItem, ILeadAccount } from 'src/types/lead';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

interface ILeadResponse {
  leads?: ILeadItem[];
  lead?: ILeadItem;
  stats?: {
    [key: string]: number;
  };
  accountsCreated?: ILeadAccount[];
  error?: string;
  message?: string;
}

interface ILeadsStatusResponse {
  leadIds: string[];
  status?: string;
  error?: string;
  message?: string;
}

interface IVerificationResponse {
  leadId: string;
  verified: boolean;
  error?: string;
  message?: string;
}

interface IBookmarkResponse {
  leadIds: string[];
  bookmarked: boolean;
  error?: string;
  message?: string;
}

interface LeadsState {
  isLoading: boolean;
  error: string | null;
  leads: ILeadItem[];
  stats: {
    [key: string]: number;
  };
  serverSearched?: boolean;
  remindedLeads: ILeadItem[];
  lead: ILeadItem | null;
  accountsCreated: ILeadAccount[];
  category: string | null;
  other: string | null;
  sortBy: string | null;
}

const initialState: LeadsState = {
  isLoading: false,
  error: null,
  leads: [],
  stats: {},
  serverSearched: false,
  remindedLeads: [],
  lead: null,
  accountsCreated: [],
  category: null,
  other: null,
  sortBy: 'createdAt',
};

const slice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET LEADS
    getLeadsSuccess(state, action: PayloadAction<ILeadResponse>) {
      state.isLoading = false;
      const newLeads = action.payload.leads ?? [];
      const existingLeads = state.leads;
      const allLeads = existingLeads.concat(newLeads);
      const uniqueLeads = allLeads.filter((lead, index, self) => {
        return index === self.findIndex((a) => a.leadId === lead.leadId && lead.status !== 'duplicate');
      });
      const sortBy = state.sortBy || 'createdAt';
      const sortedLeads = uniqueLeads.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.leads = sortedLeads;
      state.stats = action.payload.stats ?? {};
    },

    // SEARCH LEADS
    searchLeadsSuccess(state, action: PayloadAction<ILeadResponse>) {
      state.isLoading = false;
      const leads = action.payload.leads ?? [];
      const sortBy = state.sortBy || 'createdAt';
      const sortedLeads = leads.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.leads = sortedLeads;
      state.serverSearched = true;
      state.stats = action.payload.stats ?? {};
    },

    // GET REMINDED LEADS
    getRemindedLeadsSuccess(state, action: PayloadAction<ILeadResponse>) {
      state.isLoading = false;
      const remindedLeads = action.payload.leads ?? [];
      remindedLeads.forEach((lead) => {
        const leadIndex = state.leads.findIndex((l) => l.leadId === lead.leadId);
        if (leadIndex !== -1) {
          state.leads[leadIndex].remindedIn24hrs = true;
          state.leads[leadIndex].reminded = true;
        }
      });
    },

    // CREATE ACCOUNT
    createAccountSuccess(state, action: PayloadAction<ILeadResponse>) {
      state.isLoading = false;
      state.accountsCreated = action.payload.accountsCreated ?? [];
    },

    // UPDATE LEAD
    updateLeadSuccess(state, action: PayloadAction<ILeadResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const lead_data = action.payload.lead;
        if (lead_data) {
          const leadIndex = state.leads.findIndex((lead) => lead.leadId === lead_data.leadId);
          if (leadIndex !== -1) {
            state.leads[leadIndex] = lead_data;
          }
        }
      }
    },

    // GET LEAD
    getLeadSuccess(state, action: PayloadAction<ILeadResponse>) {
      state.isLoading = false;
      state.lead = action.payload.lead || null;
    },

    // UPDATE LEAD STATUS
    updateLeadsStatusSuccess(state, action: PayloadAction<ILeadsStatusResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { leadIds, status } = action.payload;
        leadIds.forEach((leadId) => {
          const leadIndex = state.leads.findIndex((lead) => lead.leadId === leadId);
          if (leadIndex !== -1 && status) {
            state.leads[leadIndex].status = status;
          }
        });
      }
    },

    // SEND ACTIVATION REMINDER
    sendActivationReminderSuccess(state, action: PayloadAction<ILeadsStatusResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { leadIds } = action.payload;
        leadIds.forEach((leadId) => {
          const leadIndex = state.leads.findIndex((lead) => lead.leadId === leadId);
          if (leadIndex !== -1) {
            state.leads[leadIndex].reminded = true;
            state.leads[leadIndex].remindedIn24hrs = true;
          }
        });
      }
    },

    // UPDATE VERIFICATION STATUS
    updateVerificationSuccess(state, action: PayloadAction<IVerificationResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { leadId, verified } = action.payload;
        const leadIndex = state.leads.findIndex((lead) => lead.leadId === leadId);
        if (leadIndex !== -1) {
          state.leads[leadIndex].verified = verified;
        }
      }
    },

    // UPDATE BOOKMARK STATUS
    updateBookmarkSuccess(state, action: PayloadAction<IBookmarkResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { leadIds, bookmarked } = action.payload;
        leadIds.forEach((leadId) => {
          const leadIndex = state.leads.findIndex((lead) => lead.leadId === leadId);
          if (leadIndex !== -1) {
            state.leads[leadIndex].bookmarked = bookmarked;
          }
        });
      }
    },

    // INVITE LEADS
    inviteLeadsSuccess(state, action: PayloadAction<ILeadsStatusResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { leadIds, status } = action.payload;
        leadIds.forEach((leadId) => {
          const leadIndex = state.leads.findIndex((lead) => lead.leadId === leadId);
          if (leadIndex !== -1 && status) {
            state.leads[leadIndex].status = status;
          }
        });
      }
    },

    // RESET LEAD SEARCH
    resetLeadsSuccess(state) {
      state.isLoading = false;
      state.leads = [];
      state.stats = {};
    },

    // SORT & FILTER LEADS
    sortByLeads(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  sortByLeads,
  resetLeadsSuccess,
  searchLeadsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getLeads(page: number, rowsPerPage: number, status?: string, subStatus?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = '/management/leads/get_active_leads?app=' + (app || 'brightpay') + '&page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      url = subStatus ? url + '&subStatus=' + subStatus : url;
      const response = await axiosBrightHub.get(url);
      dispatch(slice.actions.getLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function searchLeads(searchString: string, page: number, rowsPerPage: number, status?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (sortBy) {
        dispatch(sortByLeads(sortBy))
      }
      let url = '/management/leads/search_leads?searchString=' + searchString + '&app=' + (app || 'brightpay') + '&page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      const response = await axiosBrightHub.get(url);
      dispatch(searchLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getRemindedLeads(): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.get('/management/leads/get_reminded_leads');
      dispatch(slice.actions.getRemindedLeadsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getLead(leadId: string): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/get_lead', { leadId });
      dispatch(slice.actions.getLeadSuccess(response.data.lead));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function createAccount(users: any, account: any, locations: any): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/convert_to_account', { users, account, locations });
      dispatch(slice.actions.createAccountSuccess(response.data.accounts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLead(leadId: string, data: any): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/update_lead', { leadId, data });
      dispatch(slice.actions.updateLeadSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateLeadsStatus(leadIds: string[], status: string): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/update_status', { leadIds, status });
      dispatch(slice.actions.updateLeadsStatusSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateVerificationStatus(leadId: string, verified: boolean): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/update_verification_status', { leadId, verified });
      dispatch(slice.actions.updateVerificationSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function sendActivationReminder(leadIds: string[]): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/send_activation_reminder', { leadIds });
      dispatch(slice.actions.sendActivationReminderSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBookmark(leadIds: string[], bookmarked: boolean): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/add_bookmark', { leadIds, bookmarked });
      dispatch(slice.actions.updateBookmarkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function createAccountAndSendInvite(leadIds: string[]): AppThunk {
  return async (dispatch: AppDispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosBrightHub.post('/management/leads/create_account_and_send_invite', { leadIds });
      dispatch(slice.actions.inviteLeadsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error.message));
    }
  };
}
