import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import { IPractitionerItem, IPractitionerAvailability } from 'src/types/practitioner';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

interface IPractitionerResponse {
  practitioner?: IPractitionerItem;
  practitioners?: IPractitionerItem[];
  availability?: any[];
  stats?: {
    [key: string]: number;
  };
  error?: string;
  message?: string;
}

interface IPractitionerFilters {
  name: string;
  slug: string;
  providerId: string;
}

interface IFiltersResponse {
  filters?: string[];
  practitionerFilters?: IPractitionerFilters[]
}

interface VpaQrImageDataResponse {
  providerId: string;
  qrImage: string;
}

interface IPractitionerFlagsResponse {
  providerIds: string[];
  status?: string;
  bookmarked?: boolean;
  error?: string;
  message?: string;
}

interface PractitionersState {
  isLoading: boolean;
  error: string | null;
  practitioners: IPractitionerItem[];
  availabilityInPractice: IPractitionerAvailability[];
  vpaQrImages: {
    [key: string]: string | null;
  },
  stats: {
    [key: string]: number;
  };
  filters: string[] | IPractitionerFilters[];
  practitioner: IPractitionerItem | null;
  category: string | null;
  other: string | null;
  sortBy: string | null;
}

const initialState: PractitionersState = {
  isLoading: false,
  error: null,
  practitioners: [],
  availabilityInPractice: [],
  filters: [],
  vpaQrImages: {},
  stats: {},
  practitioner: null,
  category: null,
  other: null,
  sortBy: 'createdAt',
};

const slice = createSlice({
  name: 'practitioner',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRACTITIONERS
    getPractitionersSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      const newPractitioners = action.payload.practitioners ?? [];
      const existingPractitioners = state.practitioners;
      const allPractitioners = existingPractitioners.concat(newPractitioners);
      const uniquePractitioners = allPractitioners.filter((practitioner, index, self) => {
        return index === self.findIndex((a) => a.providerId === practitioner.providerId);
      });
      const sortBy = state.sortBy || 'createdAt';
      const sortedPractitioners = uniquePractitioners.slice().sort((a, b) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        }
        if (a[sortBy] > b[sortBy]) {
          return 1;
        }
        return 0;
      });
      state.practitioners = sortedPractitioners;
      state.stats = action.payload.stats ?? {};
    },

    // UPDATE PRACTITIONER
    updatePractitionerSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const practitioner_data = action.payload.practitioner;
        if (practitioner_data) {
          const practitionerIndex = state.practitioners.findIndex(practitioner => practitioner.providerId === practitioner_data.providerId);
          if (practitionerIndex !== -1) {
            state.practitioners = [
              ...state.practitioners.slice(0, practitionerIndex),
              practitioner_data,
              ...state.practitioners.slice(practitionerIndex + 1),
            ];
            state.practitioner = practitioner_data;
          }
        }
      }
    },

    // GET FILTERS SUCCESS
    getFiltersSuccess(state, action: PayloadAction<IFiltersResponse>) {
      state.isLoading = false;
      state.filters = action.payload.filters || [];
    },

    // GET PRACTITIONER
    getPractitionerSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      state.practitioner = action.payload.practitioner || null;
    },

    // GET PRACTITIONER AVAILABILITY
    getPractitionerAvailabilitySuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      state.availabilityInPractice = action.payload.availability || [];
    },

    // GET PRACTITIONER LOCATIONS
    getPractitionerLocationsSuccess(state, action: PayloadAction<IPractitionerResponse>) {
      state.isLoading = false;
      state.practitioner = action.payload.practitioner || null;
    },

    // GET PROVIDER VPA QR IMAGE DATA
    getProviderVpaQrImageDataSuccess(state, action: PayloadAction<VpaQrImageDataResponse>) {
      state.isLoading = false;
      state.vpaQrImages[action.payload.providerId] = action.payload.qrImage || null;
    },

    // UPDATE PRACTITIONER STATUS
    updatePractitionersStatusSuccess(state, action: PayloadAction<IPractitionerFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { providerIds, status } = action.payload;
        providerIds.forEach(providerId => {
          const practitionerIndex = state.practitioners.findIndex(practitioner => practitioner.providerId === providerId);
          if (practitionerIndex !== -1) {
            state.practitioners[practitionerIndex].status = status || 'active';
          }
        });
      }
    },

    // UPDATE BOOKMARK STATUS
    updateBookmarkSuccess(state, action: PayloadAction<IPractitionerFlagsResponse>) {
      state.isLoading = false;
      if (action.payload.error) {
        state.error = action.payload.message || null;
      } else {
        const { providerIds, bookmarked } = action.payload;
        providerIds.forEach(providerId => {
          const practitionerIndex = state.practitioners.findIndex(practitioner => practitioner.providerId === providerId);
          // Uncomment the following line if bookmarked attribute exists
          // if (practitionerIndex !== -1) {
          //   state.practitioners[practitionerIndex].bookmarked = bookmarked;
          // }
        });
      }
    },

    // SORT & FILTER PRACTITIONERS
    sortByPractitioners(state, action: PayloadAction<string>) {
      state.sortBy = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  hasError,
  startLoading,
  sortByPractitioners,
  getPractitionerSuccess,
  getPractitionersSuccess,
  updatePractitionerSuccess,
  updateBookmarkSuccess,
  getFiltersSuccess,
  getPractitionerLocationsSuccess,
  updatePractitionersStatusSuccess,
  getProviderVpaQrImageDataSuccess,
  getPractitionerAvailabilitySuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getPractitioners(page: number, rowsPerPage: number, status?: string, sortBy?: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      if (sortBy) {
        dispatch(sortByPractitioners(sortBy))
      }
      let url = '/brighthealth/practitioners/list/get?page=' + page + '&rowsPerPage=' + rowsPerPage;
      url = status ? url + '&status=' + status : url;
      url = sortBy ? url + '&sortBy=' + sortBy : url;
      const response = await axiosBrightHub.get(url);
      dispatch(getPractitionersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getLocationsByPractitioner(practitioner: string, app?: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/practitioner/manage/get_locations_by_practitioner?app=' + (app || 'brightpay') + '&practitioner_id=' + practitioner;
      const response = await axiosBrightHub.get(url);
      dispatch(getPractitionerLocationsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getProviderVpaQrImageData(providerId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      let url = '/practitioner/manage/get_location_vpa_qr_image_data?providerId=' + providerId;
      const response = await axiosBrightHub.get(url);
      dispatch(getProviderVpaQrImageDataSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export const getPractitioner = (providerId?: string): AppThunk => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axiosBrightHub.post('/management/practitioner/details', { providerId });
    dispatch(getPractitionerSuccess(response.data));
  } catch (error) {
    console.error(error);
    dispatch(hasError(error.message));
  }
};

// ----------------------------------------------------------------------

export function updatePractitioner(providerId: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update', { providerId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

// Generic function for updating, adding, and deleting attributes
export function updateAttribute(providerId: string, attribute: string, itemId: string, itemKey: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/update_attribute', { providerId, attribute, [itemKey]: itemId, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function addAttribute(providerId: string, attribute: string, data: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/add_attribute', { providerId, attribute, data });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAttribute(providerId: string, attribute: string, itemId: string, itemKey: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/delete_attribute', { providerId, attribute, [itemKey]: itemId });
      dispatch(updatePractitionerSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePractitionersStatus(providerIds: string[], status: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practitioner/manage/update_status', { providerIds, status });
      dispatch(updatePractitionersStatusSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function updateBookmark(providerIds: string[], bookmarked: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/practitioner/manage/add_bookmark', { providerIds });
      dispatch(updateBookmarkSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getCityFilters() {
  const country = process.env.REACT_APP_COUNTRY;
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.get('/management/practitioner/city_filters?country=' + country);
      dispatch(getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getSpecialtyFilters(city: string) {
  const country = process.env.REACT_APP_COUNTRY;
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.get('/management/practitioner/specialty_filters?country=' + country + '&city=' + city);
      dispatch(getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getPractitionerFilters(city: string, specialty: string) {
  const country = process.env.REACT_APP_COUNTRY;
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.get('/management/practitioner/practice_filters?country=' + country + '&city=' + city + '&specialty=' + specialty);
      dispatch(getFiltersSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getAvailabilityInPractice(practiceId: string, practitionerId: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response = await axiosBrightHub.post('/management/practitioner/availability_in_practice', { practiceId, practitionerId });
      dispatch(getPractitionerAvailabilitySuccess(response.data));
    } catch (error) {
      dispatch(hasError(error.message));
    }
  };
}