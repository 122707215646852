// routes
import { paths } from 'src/routes/paths';
import { IUserRoleDetails } from 'src/types/user';

export const ALL_ROLES = [
    'bright_super_admin',
    'bright_admin',
    'bright_agent',
    'admin',
    'user',
    'ro_admin',
    'doctor'
]

export const ROLE_DEFINITIONS: IUserRoleDetails[] = [
    {
        role: 'admin',
        label: 'Payments Admin',
        alert: 'Complete Access',
        alertColor: 'error',
        description: 'Recommended for owner / manager / admin staff who manage payments and billing',
        permissions: [
            'payments:all',
            'transactions:all',
            'reports:all',
            'paymentlinks:all',
            'settlements:all',
            'paymentpage:all'
        ]
    },
    {
        role: 'user',
        label: 'Payments User',
        alert: 'Limited Access',
        alertColor: 'info',
        description: 'Recommended for front-desk staff and other non-admin staff who accept payments',
        permissions: [
            'payments:accept',
            'payments:update',
            'transactions:view',
            'paymentlinks:send',
            'paymentlinks:view',
            'paymentpage:view'
        ]
    },
    {
        role: 'ro_admin',
        label: 'Read-Only Payments Admin',
        alert: 'View Only',
        alertColor: 'warning',
        description: 'Recommended for finance person / accountant / CA who needs to view and export reports',
        permissions: [
            'transactions:view',
            'transactions:export',
            'reports:view',
            'reports:export',
            'settlements:view',
            'settlements:export',
            'paymentpage:view'
        ]
    },
    {
        role: 'doctor',
        label: 'Doctor (Limited Access)',
        alert: 'View Their Payments',
        alertColor: 'secondary',
        description: 'Recommended for visiting/resident doctor who needs to view payments of their patients',
        permissions: [
            'transactions:view',
            'paymentpage:view'
        ]
    }
]

export const PERMISSIONS = {
    'payments:all': 'Complete Payments Access',
    'transactions:all': 'Complete Transactions Access',
    'reports:all': 'Complete Reports Access',
    'paymentlinks:all': 'Complete Payment Links Access',
    'settlements:all': 'Complete Settlements Access',
    'paymentpage:all': 'Complete Payment Page Access',
    'payments:accept': 'Accept Payments (POS)',
    'payments:refund': 'Refund Payments',
    'payments:update': 'Update Payment Details',
    'transactions:view': 'View Transactions',
    'transactions:export': 'Export Transactions',
    'reports:view': 'View Reports',
    'reports:export': 'Export Reports',
    'paymentlinks:send': 'Send Payment Links',
    'paymentlinks:view': 'View Payment Links',
    'settlements:view': 'View Settlements',
    'settlements:export': 'Export Settlements',
    'paymentpage:edit': 'Edit Payment Page',
    'paymentpage:view': 'View Payment Page',
}

export const ROLE_BASED_PATHS = {
    'bright_super_admin': paths.dashboard.brightpay.overview,
    'bright_admin': paths.dashboard.root,
    'bright_agent': paths.dashboard.root,
    'admin': paths.dashboard.root,
    'user': paths.dashboard.root,
    'ro_admin': paths.dashboard.root
}

// export const ROLE_BASED_PATHS = {
//     'bright_super_admin': paths.dashboard.brightpay.overview,
//     'bright_admin': paths.dashboard.root,
//     'bright_agent': paths.dashboard.root,
//     'admin': paths.dashboard.brightpay.overview,
//     'user': paths.dashboard.brightpay.overview,
//     'ro_admin': paths.dashboard.brightpay.overview
// }

export const BRIGHT_INTERNAL_ROLES = [
    'bright_super_admin',
    'bright_admin',
    'bright_agent',
]

export const BRIGHT_INTERNAL_ADMIN_ROLES = [
    'bright_super_admin',
    'bright_admin'
]

export const ALL_ROLES_EXCEPT_DOC = [
    'bright_super_admin',
    'bright_admin',
    'admin',
    'user',
    'ro_admin'
]

export const ADMIN_ROLES = [
    'bright_super_admin',
    'bright_admin',
    'admin',
    'ro_admin'
]

export const ADMIN_EXEC_ROLES = [
    'bright_super_admin',
    'bright_admin',
    'admin'
]