import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axios from 'src/utils/axios';
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import {
    IPaymentLinkItem,
    IPaymentModeOption,
    IPaymentLinksTableFilters
} from 'src/types/paymentlink';

import {
    ILocationFilterItem,
    IServicesFilterItem
} from 'src/types/brightpaycommon';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

interface CustomFile extends File {
    id?: string;
    path?: string;
    preview?: string;
    lastModifiedDate?: Date;
}

interface IPaymentLinksResponse {
    estimateUrl?: string;
    paymentLink?: IPaymentLinkItem;
    paymentLinks?: IPaymentLinkItem[];
    paymentModeOptions?: IPaymentModeOption[];
    amount?: number;
    count: number;
    hasMore: boolean;
    error?: string | boolean;
    message?: string;
    stats?: {
        [key: string]: number;
    };
}

function isKeyOfPaymentLinkItem(key: any): key is keyof IPaymentLinkItem {
    return ['timestamp', 'amount', 'payerName', 'payerEmail'].includes(key);
}

interface PaymentsState {
    isLoading: boolean;
    error: string | null;
    estimateUrl: string | null;
    paymentLink: IPaymentLinkItem | null;
    paymentLinkSent: boolean;
    paymentLinks?: IPaymentLinkItem[];
    paymentModeOptions?: IPaymentModeOption[];
    locationFilters: ILocationFilterItem[];
    servicesFilters: IServicesFilterItem[];
    paymentLinkId: string | null;
    providerId: string | null;
    category: string | null;
    sortBy: string;
    stats: {
        [key: string]: number;
    };
}

const initialState: PaymentsState = {
    isLoading: false,
    error: null,
    estimateUrl: null,
    paymentLink: null,
    paymentLinkSent: false,
    paymentLinks: [],
    paymentModeOptions: [],
    stats: {},
    locationFilters: [],
    servicesFilters: [],
    paymentLinkId: null,
    providerId: null,
    category: null,
    sortBy: 'timestamp',
};

const slice = createSlice({
    name: 'paymentLink',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        appendPaymentLinksSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
        },

        // GET ACCOUNTS
        getPaymentLinksSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
            let uniquePaymentlinks = action.payload.paymentLinks ?? []

            const sortBy = state.sortBy || 'timestamp';

            const sortedPaymentlinks = uniquePaymentlinks.slice().sort((a, b) => {
                if (!isKeyOfPaymentLinkItem(sortBy)) {
                    return 0;
                }

                // Using optional chaining for safety
                const aValue = a[sortBy] ?? 0;
                const bValue = b[sortBy] ?? 0;

                if (aValue < bValue) {
                    return -1;
                }
                if (aValue > bValue) {
                    return 1;
                }
                return 0;
            });

            state.paymentLinks = sortedPaymentlinks;
        },

        // SEND EMI PAYMENT LINK
        sendEmiPaymentLinkSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
            state.paymentLink = action.payload.paymentLink || null;
            if (action.payload.error == false) {
                state.paymentLinkSent = true;
            }
        },

        // SEND PAYMENT LINK
        sendPaymentLinkSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
            state.paymentLink = action.payload.paymentLink || null;
            if (action.payload.error == false) {
                state.paymentLinkSent = true;
            }
        },

        // GET ACCOUNT
        getPaymentLinkSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
            state.paymentLink = action.payload.paymentLink || null;
        },

        // GET ACCOUNT
        getPaymentLinkStatsSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
            state.stats = action.payload.stats || {};
        },

        // GET PAYMENT MODE OPTIONS
        getPaymentModeOptionsSuccess(state, action: PayloadAction<IPaymentLinksResponse>) {
            state.isLoading = false;
            state.paymentModeOptions = action.payload.paymentModeOptions || [];
        },

        // GET LOCATION FILTER VALUES
        getLocationFiltersSuccess(state, action: PayloadAction<ILocationFilterItem[]>) {
            state.isLoading = false;
            state.locationFilters = action.payload || [];
        },

        // SET ESTIMATE URL
        setEstimateUrl(state, action: PayloadAction<{ fileUrl: string }>) {
            state.estimateUrl = action.payload.fileUrl;
        },

        // GET SERVICES FILTER VALUES
        getServicesFiltersSuccess(state, action: PayloadAction<IServicesFilterItem[]>) {
            state.isLoading = false;
            state.servicesFilters = action.payload || [];
        },

        //  SORT & FILTER PRODUCTS
        sortByPaymentLinks(state, action: PayloadAction<string>) {
            state.sortBy = action.payload;
        },

        // RESET PAYMENT LINK
        resetPaymentLink(state) {
            state.paymentLink = null;
            state.paymentLinkSent = false;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    startLoading,
    setEstimateUrl,
    resetPaymentLink,
    sortByPaymentLinks,
    getPaymentLinkSuccess,
    getPaymentLinksSuccess,
    sendPaymentLinkSuccess,
    sendEmiPaymentLinkSuccess,
    getLocationFiltersSuccess,
    getServicesFiltersSuccess,
    appendPaymentLinksSuccess,
    getPaymentLinkStatsSuccess,
    getPaymentModeOptionsSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function uploadEstimateUrl(file: CustomFile) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brightpay/paymentlinks/common/create_signed_url', { fileId: file.id, fileName: file.path });
            if (response.data) {
                await axios.put(response.data.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Content-Encoding': 'base64',
                        'Skip-Auth': 'true'
                    }
                });
                dispatch(setEstimateUrl(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export const sendEmiPaymentLink = (data: any): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/paymentlinks/emi/create_and_send_link', data);
        dispatch(sendEmiPaymentLinkSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
}

// ----------------------------------------------------------------------

export const sendPaymentLink = (data: any): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/paymentlinks/paynow/create_and_send_link', data);
        dispatch(sendPaymentLinkSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
}

// ----------------------------------------------------------------------

export const sendQuickPaymentRequest = (data: any): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/paymentlinks/paynow/request_payment', data);
        dispatch(sendPaymentLinkSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
}

// ----------------------------------------------------------------------

export const getPaymentModeOptions = (amount: number): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/common/get_payment_mode_options', { amount });
        dispatch(getPaymentModeOptionsSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export const getPaymentLinkDetails = (transactionId: string): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/paymentlinks/manage/get', { transactionId });
        dispatch(getPaymentLinkSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export function getPaymentLinks(page: number, rowsPerPage: number, filters: IPaymentLinksTableFilters, sortBy?: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            if (sortBy) {
                dispatch(sortByPaymentLinks(sortBy))
            }
            let url = '/brightpay/paymentlinks/manage/list?page=' + page + '&rowsPerPage=' + rowsPerPage;
            url = sortBy ? url + '&sortBy=' + sortBy : url;
            const response = await axiosBrightHub.post(url, filters);
            // Add a check to see if the next query is for next page, otherwise replace state.payments with response.data.payments
            if (false) {
                // Append the new data to the existing state.payments
                dispatch(appendPaymentLinksSuccess(response.data));
            } else {
                // Replace the state.payments with the new data
                dispatch(getPaymentLinksSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export const getLocationFilters = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/common/get_locations_for_filter');
        dispatch(getLocationFiltersSuccess(response.data.locations));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export const getServicesForPaymentLinks = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/common/get_services_for_payment_link');
        dispatch(getServicesFiltersSuccess(response.data.services));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};