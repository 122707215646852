import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axios from 'src/utils/axios';
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import {
    ICreditType,
    ICreditItem,
    ICreditsTableFilters
} from 'src/types/credit';

import {
    ILocationFilterItem
} from 'src/types/brightpaycommon';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

interface ICreditsResponse {
    creditBalance?: number;
    credits?: ICreditItem[];
    credit?: ICreditItem;
    count: number;
    hasMore: boolean;
    error?: string;
    message?: string;
    stats?: {
        [key: string]: {
            credits: number;
            creditsVolume: number;
        };
    };
}

function isKeyOfCreditItem(key: any): key is keyof ICreditItem {
    return ['timestamp', 'credits', 'payerName', 'type'].includes(key);
}

interface CreditsState {
    isLoading: boolean;
    error: string | null;
    credit: ICreditItem | null;
    creditBalance?: number;
    creditsVolume: number | null;
    creditsCount: number | null;
    credits?: ICreditItem[];
    locationFilters: ILocationFilterItem[];
    creditId: string | null;
    providerId: string | null;
    sortBy: string;
    stats: {
        [key: string]: {
            credits: number;
            creditsVolume: number;
        };
    };
}

const initialState: CreditsState = {
    isLoading: false,
    error: null,
    credit: null,
    creditBalance: 0,
    creditsVolume: null,
    creditsCount: null,
    credits: [],
    stats: {},
    locationFilters: [],
    creditId: null,
    providerId: null,
    sortBy: 'timestamp',
};

const slice = createSlice({
    name: 'credit',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        appendCreditsSuccess(state, action: PayloadAction<ICreditsResponse>) {
            state.isLoading = false;
        },

        // GET ACCOUNTS
        getCreditsSuccess(state, action: PayloadAction<ICreditsResponse>) {
            state.isLoading = false;
            const uniqueCredits = action.payload.credits || [];

            const sortBy = state.sortBy || 'timestamp';

            const sortedCredits = uniqueCredits.slice().sort((a, b) => {
                if (!isKeyOfCreditItem(sortBy)) {
                    return 0;
                }

                // Using optional chaining for safety
                const aValue = a[sortBy] ?? 0;
                const bValue = b[sortBy] ?? 0;

                if (aValue < bValue) {
                    return -1;
                }
                if (aValue > bValue) {
                    return 1;
                }
                return 0;
            });

            state.credits = sortedCredits;
            state.stats = action.payload.stats || {};
        },

        // GET ACCOUNT
        getCreditSuccess(state, action: PayloadAction<ICreditsResponse>) {
            state.isLoading = false;
            state.credit = action.payload.credit || null;
        },

        // GET CREDIT BALANCE
        getCreditBalanceSuccess(state, action: PayloadAction<ICreditsResponse>) {
            state.isLoading = false;
            state.creditBalance = action.payload.creditBalance || 0;
        },

        // GET LOCATION FILTER VALUES
        getLocationFiltersSuccess(state, action: PayloadAction<ILocationFilterItem[]>) {
            state.isLoading = false;
            state.locationFilters = action.payload || [];
        },

        //  SORT & FILTER PRODUCTS
        sortByCredits(state, action: PayloadAction<string>) {
            state.sortBy = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    startLoading,
    sortByCredits,
    getCreditSuccess,
    getCreditsSuccess,
    getCreditBalanceSuccess,
    getLocationFiltersSuccess,
    appendCreditsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getCreditBalance() {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            let url = '/brightpay/credits/get_balance';
            const response = await axiosBrightHub.post(url);
            dispatch(getCreditBalanceSuccess(response.data));
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export const getCredit = (creditId: string): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/credits/credit/get', { creditId });
        dispatch(getCreditSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export function getCredits(page: number, rowsPerPage: number, filters: ICreditsTableFilters, sortBy?: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            if (sortBy) {
                dispatch(sortByCredits(sortBy))
            }
            let url = '/brightpay/credits/credits/get_credits?page=' + page + '&rowsPerPage=' + rowsPerPage;
            url = sortBy ? url + '&sortBy=' + sortBy : url;
            const response = await axiosBrightHub.post(url, filters);
            // Add a check to see if the next query is for next page, otherwise replace state.credits with response.data.credits
            if (false) {
                // Append the new data to the existing state.credits
                dispatch(appendCreditsSuccess(response.data));
            } else {
                // Replace the state.credits with the new data
                dispatch(getCreditsSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export const getLocationFilters = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/common/get_locations_for_filter');
        dispatch(getLocationFiltersSuccess(response.data.locations));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};