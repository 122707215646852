import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import SvgColor from 'src/components/svg-color';

import {
  ALL_ROLES,
  ADMIN_ROLES,
  BRIGHT_INTERNAL_ROLES,
  ADMIN_EXEC_ROLES,
  ALL_ROLES_EXCEPT_DOC,
  BRIGHT_INTERNAL_ADMIN_ROLES
} from 'src/data/accesscontrol';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  team: icon('ic_team'),
  help: icon('ic_help'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  clinic: icon('ic_clinic'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  health: icon('ic_health'),
  patient: icon('ic_patient'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  txnDollar: icon('ic_txn_dollar'),
  txnRupee: icon('ic_txn_rupee'),
  creditCoin: icon('ic_credit_coin'),
  healthUser: icon('ic_health_user'),
  faqFill: icon('ic_question_fill'),
  rotatingSun: icon('ic_animated_sun'),
  brightPay: icon('ic_bright_sun'),
  marketingLeads: icon('ic_marketing_leads'),
};

// ----------------------------------------------------------------------

// roles: ['all'],

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
            roles: BRIGHT_INTERNAL_ADMIN_ROLES,
          },
          {
            title: t('ecommerce'),
            path: paths.dashboard.general.ecommerce,
            icon: ICONS.ecommerce,
            roles: [],
          },
          {
            title: t('payments'),
            path: paths.dashboard.brightpay.overview,
            icon: ICONS.txnRupee,
            roles: ALL_ROLES_EXCEPT_DOC,
          },
          {
            title: t('credits'),
            path: paths.dashboard.general.analytics,
            icon: ICONS.creditCoin,
            roles: [],
          },
          {
            title: t('patient leads'),
            path: paths.dashboard.general.analytics,
            icon: ICONS.healthUser,
            roles: ['bright_super_admin'],
          }
        ],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('management'),
        items: [
          // BRIGHTPAY
          {
            title: t('brightpay'),
            path: paths.dashboard.brightpay.transactions,
            icon: ICONS.brightPay,
            roles: [...ALL_ROLES, ...BRIGHT_INTERNAL_ROLES],
            children: [
              { 
                title: t('transactions'), 
                path: paths.dashboard.brightpay.transactions, 
                roles: ALL_ROLES
              },
              // { 
              //   title: t('settlements'),
              //   path: paths.dashboard.brightpay.settlements,
              //   roles: ADMIN_ROLES
              // },
              // { 
              //   title: t('credits'),
              //   path: paths.dashboard.brightpay.credits,
              //   roles: ADMIN_ROLES
              // },
              { 
                title: t('payment_requests'),
                path: paths.dashboard.brightpay.paymentRequests,
                roles: ALL_ROLES_EXCEPT_DOC
              },
              { 
                title: t('payment_pages'),
                path: paths.dashboard.brightpay.paymentPages,
                roles: [...ALL_ROLES, ...BRIGHT_INTERNAL_ROLES],
              },
              {
                title: t('my_qr_code'),
                path: paths.dashboard.brightpay.myQrCode,
                roles: ALL_ROLES_EXCEPT_DOC
              },
              // { 
              //   title: t('reports'),
              //   path: paths.dashboard.brightpay.reports,
              //   roles: ADMIN_ROLES
              // },
            ],
          },

          // BRIGHTHEALTH
          {
            title: t('brighthealth'),
            path: paths.dashboard.brighthealth.practices,
            icon: ICONS.rotatingSun,
            children: [
              { title: t('clinic-profile'), path: paths.dashboard.brighthealth.myclinicprofile, roles: [...ADMIN_ROLES, ...BRIGHT_INTERNAL_ROLES] },
              { title: t('my-profile'), path: paths.dashboard.brighthealth.myprofile, roles: ['doctor'], },
              { title: t('practices'), path: paths.dashboard.brighthealth.practices, roles: ALL_ROLES },
              { title: t('practitioners'), path: paths.dashboard.brighthealth.practitioners, roles: ALL_ROLES },
              { title: t('services_master'), path: paths.dashboard.brighthealth.services.master, roles: BRIGHT_INTERNAL_ADMIN_ROLES },
              { title: t('services'), path: paths.dashboard.brighthealth.services.manage, roles: BRIGHT_INTERNAL_ADMIN_ROLES },
              {
                title: t('packages'), path: paths.dashboard.brighthealth.packages, roles: BRIGHT_INTERNAL_ADMIN_ROLES,
              }
            ],
          },

          // PATIENTS
          {
            title: t('patients'),
            path: paths.dashboard.user.root,
            icon: ICONS.patient,
            roles: ['super_admin'],
            children: [
              { title: t('profile'), path: paths.dashboard.user.root },
              { title: t('cards'), path: paths.dashboard.user.cards, roles: ['super_admin', 'minimals_admin'] },
              { title: t('list'), path: paths.dashboard.user.list },
              { title: t('add'), path: paths.dashboard.user.new },
              { title: t('account'), path: paths.dashboard.user.account },
            ],
          },

          // LEADS
          {
            title: t('leads'),
            path: paths.dashboard.leads.list,
            icon: ICONS.marketingLeads,
            roles: BRIGHT_INTERNAL_ROLES,
            children: [
              { title: t('list'), path: paths.dashboard.leads.list }
            ],
          },

          // ACCOUNTS
          {
            title: t('accounts'),
            path: paths.dashboard.accounts.root,
            icon: ICONS.clinic,
            roles: BRIGHT_INTERNAL_ROLES,
            children: [
              { 
                title: t('list'),
                path: paths.dashboard.accounts.list,
                roles: BRIGHT_INTERNAL_ADMIN_ROLES
              },
              {
                title: t('search'),
                path: paths.dashboard.accounts.search,
                roles: BRIGHT_INTERNAL_ROLES
              },
              { title: t('activity'), path: paths.dashboard.accounts.activity, roles: BRIGHT_INTERNAL_ADMIN_ROLES },
              { title: t('logistics'), path: paths.dashboard.accounts.logistics, roles: BRIGHT_INTERNAL_ADMIN_ROLES }
            ],
          },

          // USER
          {
            title: t('users'),
            path: paths.dashboard.user.list,
            icon: ICONS.user,
            roles: BRIGHT_INTERNAL_ADMIN_ROLES
          },

          // USER
          {
            title: t('team'),
            path: paths.dashboard.team.manage,
            icon: ICONS.team,
            roles: ADMIN_EXEC_ROLES
          },

          // SUPPORT
          /*{
            title: t('support'),
            path: paths.dashboard.user.list,
            icon: ICONS.help,
            roles: ['all'],
            children: [
              { title: t('help-center'), path: paths.dashboard.support.root, roles: ['all'] },
              { title: t('submit-ticket'), path: paths.dashboard.support.submitTicket, roles: BRIGHT_INTERNAL_ROLES },
              { title: t('support-history'), path: paths.dashboard.support.tickets, roles: BRIGHT_INTERNAL_ROLES }
            ],
          },*/

          // ORDER
          {
            title: t('order'),
            path: paths.dashboard.order.root,
            icon: ICONS.order,
            roles: ['minimals_admin'],
            children: [
              { title: t('list'), path: paths.dashboard.order.root, roles: ['minimals_admin'] },
              { title: t('details'), path: paths.dashboard.order.demo.details, roles: ['minimals_admin'] },
            ],
          },

          // FAQs
          {
            title: t('FAQs'),
            path: paths.dashboard.job.root,
            icon: ICONS.faqFill,
            roles: BRIGHT_INTERNAL_ADMIN_ROLES,
            children: [
              { title: t('list'), path: paths.dashboard.job.root },
              { title: t('details'), path: paths.dashboard.job.demo.details },
              { title: t('create'), path: paths.dashboard.job.new },
              { title: t('edit'), path: paths.dashboard.job.demo.edit },
            ],
          },

          // CHAT
          {
            title: t('chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
            roles: BRIGHT_INTERNAL_ADMIN_ROLES,
          },

          // CALENDAR
          {
            title: t('calendar'),
            path: paths.dashboard.calendar,
            icon: ICONS.calendar,
            roles: ['minimals_admin'],
          },

        ],
      },
    ],
    [t]
  );

  return data;
}
