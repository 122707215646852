import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axios from 'src/utils/axios';
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import {
    IPayerItem,
    TransactionEntity,
    ITransactionItem,
    ITransactionsTableFilters
} from 'src/types/transaction';

import {
    ILocationFilterItem
} from 'src/types/brightpaycommon';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

interface CustomFile extends File {
    id?: string;
    path?: string;
    preview?: string;
    lastModifiedDate?: Date;
}

interface ITransactionsResponse {
    payments?: ITransactionItem[];
    refunds?: ITransactionItem[];
    disputes?: ITransactionItem[];
    payment?: ITransactionItem;
    amount?: number;
    count: number;
    percentChange?: {
        amount: number;
        count: number;
    },
    series?: {
        period: number;
        txns: number;
        txnVolume: number;
    }[],
    entity: TransactionEntity;
    hasMore: boolean;
    error?: string;
    message?: string;
    stats?: {
        [key: string]: {
            transactions: number;
            transctionVolume: number;
        };
    };
}

interface IPayersResponse {
    payers?: IPayerItem[];
}

// interface IPaymentLinksResponse {
//     paymentLinks: IPaymentLinkItem[];
//     count: number;
//     entity: string;
//     hasMore: boolean;
//     error?: string;
//     message?: string;
// }

function isKeyOfTransactionItem(key: any): key is keyof ITransactionItem {
    return ['timestamp', 'amount', 'payerName', 'payerEmail'].includes(key);
}

interface PaymentsState {
    isLoading: boolean;
    error: string | null;
    payment: ITransactionItem | null;
    paymentVolume: number | null;
    yearlyAggregatePaymentVolume: {
        [key: string]: {
            transactions: number;
            transactionVolume: number;
        };
    },
    yearlyPaymentVolume: {
        name: string;
        data: {
            name: string;
            data: number[];
        }[]
    }[],
    paymentCount: number | null;
    paymentsPercentChange: {
        amount: number;
        count: number;
    } | null;
    paymentSeries: {
        period: number;
        txns: number;
        txnVolume: number;
    }[],
    payers?: IPayerItem[];
    payments?: ITransactionItem[];
    refunds?: ITransactionItem[];
    disputes?: ITransactionItem[];
    locationFilters: ILocationFilterItem[];
    transactionId: string | null;
    providerId: string | null;
    category: string | null;
    sortBy: string;
    stats: {
        [key: string]: {
            transactions: number;
            transctionVolume: number;
        };
    };
}

const initialState: PaymentsState = {
    isLoading: false,
    error: null,
    payment: null,
    paymentVolume: null,
    yearlyAggregatePaymentVolume: {},
    yearlyPaymentVolume: [],
    paymentCount: null,
    paymentsPercentChange: null,
    paymentSeries: [],
    payments: [],
    payers: [],
    refunds: [],
    disputes: [],
    stats: {},
    locationFilters: [],
    transactionId: null,
    providerId: null,
    category: null,
    sortBy: 'timestamp',
};

const slice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        appendTransactionsSuccess(state, action: PayloadAction<ITransactionsResponse>) {
            state.isLoading = false;
        },

        // GET ACCOUNTS
        getTransactionsSuccess(state, action: PayloadAction<ITransactionsResponse>) {
            state.isLoading = false;
            let uniqueTransactions: ITransactionItem[] = [];

            if (['payments', 'refunds', 'disputes'].includes(action.payload.entity)) {
                uniqueTransactions = action.payload[action.payload.entity] ?? []
            }

            const sortBy = state.sortBy || 'timestamp';

            // const sortedTransactions = uniqueTransactions.slice().sort((a, b) => {
            //     if (!isKeyOfTransactionItem(sortBy)) {
            //         return 0;
            //     }

            //     // Using optional chaining for safety
            //     const aValue = a[sortBy] ?? 0;
            //     const bValue = b[sortBy] ?? 0;

            //     if (aValue < bValue) {
            //         return -1;
            //     }
            //     if (aValue > bValue) {
            //         return 1;
            //     }
            //     return 0;
            // });

            state.payments = uniqueTransactions
            // state.payments = sortedTransactions;
            state.stats = action.payload.stats || {};
        },

        // UPDATE ACCOUNT
        // updateTransactionSuccess(state, action: PayloadAction<ITransactionResponse>) {
        //     state.isLoading = false;
        //     if (action.payload.error) {
        //         state.error = action.payload.message || null;
        //     } else {
        //         const transaction_data = action.payload.payment;
        //         if (transaction_data) {
        //             const transactionIndex = state.transaction.findIndex(transaction => transaction.transactionId === transaction_data.transactionId);
        //             if (transactionIndex !== -1) {
        //                 state.transaction[transactionIndex] = transaction_data; // Ensure we only update when transaction_data is not null.
        //             }
        //         }
        //     }
        // },

        // GET ACCOUNT
        getPaymentSuccess(state, action: PayloadAction<ITransactionsResponse>) {
            state.isLoading = false;
            state.payment = action.payload.payment || null;
        },

        // GET PAYMENT VOLUME
        getPaymentVolumeSuccess(state, action: PayloadAction<ITransactionsResponse>) {
            state.isLoading = false;
            state.paymentVolume = action.payload.amount || null;
            state.paymentCount = action.payload.count || null;
            state.paymentSeries = action.payload.series || [];
            state.paymentsPercentChange = action.payload.percentChange || null;
        },

        // GET YEARLY PAYMENT VOLUME
        getYearlyPaymentVolumeSuccess(state, action: PayloadAction<any>) {
            state.isLoading = false;
            state.yearlyPaymentVolume = action.payload.yearlyData || [];
            state.yearlyAggregatePaymentVolume = action.payload.aggregateData || {};
        },

        // GET RECENT PAYERS
        getRecentPayersSuccess(state, action: PayloadAction<IPayersResponse>) {
            state.isLoading = false;
            state.payers = action.payload.payers || [];
        },

        // GET LOCATION FILTER VALUES
        getLocationFiltersSuccess(state, action: PayloadAction<ILocationFilterItem[]>) {
            state.isLoading = false;
            state.locationFilters = action.payload || [];
        },

        // GET PROVIDER VPA QR IMAGE DATA
        // getProviderVpaQrImageDataSuccess(state, action: PayloadAction<VpaQrImageDataResponse>) {
        //     state.isLoading = false;
        //     state.vpaQrImages[action.payload.providerId] = action.payload.qrImage || null;
        // },

        // UPDATE ACCOUNT STATUS
        // updateTransactionsStatusSuccess(state, action: PayloadAction<ITransactionFlagsResponse>) {
        //     state.isLoading = false;
        //     if (action.payload.error) {
        //         state.error = action.payload.message || null;
        //     } else {
        //         const { transactionIds, status } = action.payload;
        //         transactionIds.forEach(transactionId => {
        //             const transactionIndex = state.transaction.findIndex(transaction => transaction.transactionId === transactionId);
        //             if (transactionIndex !== -1) {
        //                 state.transaction[transactionIndex].status = status || null;
        //             }
        //         });
        //     }
        // },


        //  SORT & FILTER PRODUCTS
        sortByTransactions(state, action: PayloadAction<string>) {
            state.sortBy = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    startLoading,
    sortByTransactions,
    getPaymentSuccess,
    getTransactionsSuccess,
    getRecentPayersSuccess,
    getPaymentVolumeSuccess,
    getLocationFiltersSuccess,
    appendTransactionsSuccess,
    getYearlyPaymentVolumeSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export const getPayment = (transactionId: string): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/transactions/payment/get', { transactionId });
        dispatch(getPaymentSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export const getPaymentVolume = (startDate: number, endDate: number, locations?: string[]): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/transactions/payments/get_payment_volume', { startDate, endDate, locations });
        dispatch(getPaymentVolumeSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};
// ----------------------------------------------------------------------

export const getYearlyPaymentVolume = (locations?: string[]): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/transactions/payments/get_yearly_payment_volume', { locations });
        dispatch(getYearlyPaymentVolumeSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export const getRecentPayers = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.get('/brightpay/transactions/payments/get_recent_payers');
        dispatch(getRecentPayersSuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export function getPayments(page: number, rowsPerPage: number, filters: ITransactionsTableFilters, sortBy?: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            if (sortBy) {
                dispatch(sortByTransactions(sortBy))
            }
            let url = '/brightpay/transactions/payments/get_payments?page=' + page + '&rowsPerPage=' + rowsPerPage;
            url = sortBy ? url + '&sortBy=' + sortBy : url;
            const response = await axiosBrightHub.post(url, filters);
            // Add a check to see if the next query is for next page, otherwise replace state.payments with response.data.payments
            if (false) {
                // Append the new data to the existing state.payments
                dispatch(appendTransactionsSuccess(response.data));
            } else {
                // Replace the state.payments with the new data
                dispatch(getTransactionsSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export const getLocationFilters = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/common/get_locations_for_filter');
        dispatch(getLocationFiltersSuccess(response.data.locations));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export function uploadFile(fileType: string, transactionId: string, file: CustomFile) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            const response = await axiosBrightHub.post('/brightpay/transactions/payment/create_signed_url', { fileType, transactionId, fileId: file.id, fileName: file.path });
            if (response.data) {
                await axios.put(response.data.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                        'Content-Encoding': 'base64',
                        'Skip-Auth': 'true'
                    }
                });
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

// export function updateTransaction(transactionId: string, data: any) {
//     return async (dispatch: AppDispatch) => {
//         dispatch(startLoading());
//         try {
//             const response = await axios.post('/transaction/manage/update', { transactionId, data });
//             dispatch(updatePaymentSuccess(response.data));
//         } catch (error) {
//             console.error(error);
//             dispatch(hasError(error));
//         }
//     };
// }

// ----------------------------------------------------------------------

// export function updateTransactionsStatus(transactionIds: string[], status: string) {
//     return async (dispatch: AppDispatch) => {
//         dispatch(startLoading());
//         try {
//             const response = await axios.post('/transaction/manage/update_status', { transactionIds, status });
//             dispatch(updateTransactionsStatusSuccess(response.data));
//         } catch (error) {
//             console.error(error);
//             dispatch(hasError(error));
//         }
//     };
// }