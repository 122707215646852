import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
// utils
import axios from 'src/utils/axios';
import axiosBrightHub from 'src/utils/axiosBrightHub';
//
import { RootState, AppDispatch } from '../store';

// types
import {
    ISettlementItem,
    ISettlementsTableFilters
} from 'src/types/settlement';

// ----------------------------------------------------------------------

type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

// interface ICreditsResponse {
//     credits: ICreditItem[];
//     count: number;
//     entity: string;
//     hasMore: boolean;
//     error?: string;
//     message?: string;
// }

interface ISettlementsSummaryResponse {
    todaysSettlement: number;
    latestSettlement: {
        netSettlement: number;
        timestamp: number;
        settlementId: string;
        utr?: string;
    };
}

interface ISettlementsResponse {
    settlements?: ISettlementItem[];
    settlement?: ISettlementItem;
    count: number;
    hasMore: boolean;
    error?: string;
    message?: string;
}

function isKeyOfSettlementItem(key: any): key is keyof ISettlementItem {
    return ['timestamp', 'netSettlement', 'utr', 'status'].includes(key);
}

interface SettlementsState {
    isLoading: boolean;
    error: string | null;
    settlement: ISettlementItem | null;
    settlements?: ISettlementItem[];
    settlementId: string | null;
    providerId?: string | null;
    category?: string | null;
    sortBy?: string;
    todaysSettlement?: number | null;
    latestSettlement?: {
        netSettlement: number;
        timestamp: number;
        settlementId: string;
        utr?: string;
    } | null;
}

const initialState: SettlementsState = {
    isLoading: false,
    error: null,
    settlement: null,
    settlements: [],
    settlementId: null,
    providerId: null,
    category: null,
    todaysSettlement: null,
    latestSettlement: null,
    sortBy: 'timestamp',
};

const slice = createSlice({
    name: 'settlement',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },

        appendSettlementsSuccess(state, action: PayloadAction<ISettlementsResponse>) {
            state.isLoading = false;
        },

        // GET ACCOUNTS
        getSettlementsSuccess(state, action: PayloadAction<ISettlementsResponse>) {
            state.isLoading = false;
            const uniqueSettlements = action.payload.settlements ?? []

            const sortBy = state.sortBy || 'timestamp';

            const sortedSettlements = uniqueSettlements.slice().sort((a, b) => {
                if (!isKeyOfSettlementItem(sortBy)) {
                    return 0;
                }

                // Using optional chaining for safety
                const aValue = a[sortBy] ?? 0;
                const bValue = b[sortBy] ?? 0;

                if (aValue < bValue) {
                    return -1;
                }
                if (aValue > bValue) {
                    return 1;
                }
                return 0;
            });

            // state.settlements = sortedSettlements;
            state.settlements = uniqueSettlements;
        },

        // GET ACCOUNT
        getSettlementSuccess(state, action: PayloadAction<ISettlementsResponse>) {
            state.isLoading = false;
            state.settlement = action.payload.settlement || null;
        },

        // GET ACCOUNT
        getSettlementsSummarySuccess(state, action: PayloadAction<ISettlementsSummaryResponse>) {
            state.isLoading = false;
            state.todaysSettlement = action.payload.todaysSettlement || null;
            state.latestSettlement = action.payload.latestSettlement || {};
        },

        //  SORT & FILTER PRODUCTS
        sortBySettlements(state, action: PayloadAction<string>) {
            state.sortBy = action.payload;
        },

    },
});

// Reducer
export default slice.reducer;

// Actions
export const {
    hasError,
    startLoading,
    sortBySettlements,
    getSettlementSuccess,
    getSettlementsSuccess,
    appendSettlementsSuccess,
    getSettlementsSummarySuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export const getPayment = (settlementId: string): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axios.post('/brightpay/settlements/get_settlements', { settlementId });
        dispatch(getSettlementSuccess(response.data.settlement));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export const getSettlementsSummary = (): AppThunk => async (dispatch) => {
    dispatch(startLoading());
    try {
        const response = await axiosBrightHub.post('/brightpay/settlements/get_summary');
        dispatch(getSettlementsSummarySuccess(response.data));
    } catch (error) {
        console.error(error);
        dispatch(hasError(error));
    }
};

// ----------------------------------------------------------------------

export function getSettlements(page: number, rowsPerPage: number, filters: ISettlementsTableFilters, sortBy?: string) {
    return async (dispatch: AppDispatch) => {
        dispatch(startLoading());
        try {
            if (sortBy) {
                dispatch(sortBySettlements(sortBy))
            }
            let url = '/brightpay/settlements/get_settlements?page=' + page + '&rowsPerPage=' + rowsPerPage;
            url = sortBy ? url + '&sortBy=' + sortBy : url;
            const response = await axiosBrightHub.post(url, filters);
            // Add a check to see if the next query is for next page, otherwise replace state.payments with response.data.payments
            if (false) {
                // Append the new data to the existing state.payments
                dispatch(appendSettlementsSuccess(response.data));
            } else {
                // Replace the state.payments with the new data
                dispatch(getSettlementsSuccess(response.data));
            }
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}