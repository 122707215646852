import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import transactionReducer from './slices/transaction';
import settlementReducer from './slices/settlement';
import paymentlinkReducer from './slices/paymentlink';
import creditReducer from './slices/credit';
import accountsReducer from './slices/accounts';
import activationReducer from './slices/activation';
import leadReducer from './slices/leads';
import practiceReducer from './slices/practice';
import practitionerReducer from './slices/practitioner';
import servicesReducer from './slices/services';
import userReducer from './slices/user';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  account: accountsReducer,
  practice: practiceReducer,
  transaction: transactionReducer,
  settlement: settlementReducer,
  activation: activationReducer,
  paymentlink: paymentlinkReducer,
  credit: creditReducer,
  practitioner: practitionerReducer,
  services: servicesReducer,
  user: userReducer,
  lead: leadReducer
});

export { rootPersistConfig, rootReducer };