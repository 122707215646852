import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import MainLayout from 'src/layouts/main';
import DashboardLayout from 'src/layouts/dashboard';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes, IndexPage } from './dashboard';
import { componentsRoutes } from './components';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // SET INDEX PAGE WITH DASHBOARD PAGE
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <IndexPage />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
