import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
//
import { useAuthContext } from '../hooks';

import { useAuth0 } from '@auth0/auth0-react';
import { useUpdateAuthToken } from 'src/utils/use-update-auth0-token';
import { getAccessToken } from 'src/utils/auth0-token-manager';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.jwt.login,
  // auth0: paths.auth.auth0.login,
  // auth0: paths.auth.login,
  auth0: paths.root,
  // amplify: paths.auth.amplify.login,
  amplify: paths.auth.login,
  firebase: paths.auth.firebase.login,
};

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const router = useRouter();
  useUpdateAuthToken();

  const { authenticated, method, getAccessToken, zohoHCAsapToken } = useAuthContext();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();

      sessionStorage.setItem('returnToPath', window.location.pathname);

      const loginPath = loginPaths[method];

      const href = `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router]);

  // Commenting Zoho till rest of developments are complete
  // useEffect(() => {
  //   const setZohoJWT = async () => {
  //     if (authenticated) {
  //       try {
  //         if (zohoHCAsapToken === '') return;
  //         window.ZohoHCAsapSettings.userInfo.jwtToken = zohoHCAsapToken;
  //       } catch (error) {
  //         console.error("Error obtaining JWT token from Auth0", error);
  //       }
  //     }
  //   };

  //   setZohoJWT();
  // }, [authenticated, zohoHCAsapToken]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
