// src/hooks/useUpdateAuthToken.ts

import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setAccessToken } from './auth0-token-manager';

//
import { useAuthContext } from 'src/auth/hooks';

export const useUpdateAuthToken = () => {
    // const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const { authenticated, getAccessToken } = useAuthContext();

    useEffect(() => {
        const updateToken = async () => {
            if (authenticated) {
                try {
                    const token = await getAccessToken();
                    setAccessToken(token);
                } catch (error) {
                    console.error('Error getting access token:', error);
                    setAccessToken(null);
                }
            }
        };

        updateToken();
    }, [getAccessToken, authenticated]);
};