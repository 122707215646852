// src/utils/axiosBrightHub.js

import axios from 'axios';
// import { BRIGHTHUB_HOST_API } from 'src/config-global';
import { getAccessToken } from './auth0-token-manager';

// const axiosInstance = axios.create({ baseURL: BRIGHTHUB_HOST_API });
// console.log('process.env.BRIGHTHUB_SERVER_API', process.env.REACT_APP_BRIGHTHUB_SERVER_API)
const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_BRIGHTHUB_SERVER_API });

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const token = await getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("Error obtaining Auth0 token:", error);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;